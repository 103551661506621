//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      rooms: [
        {
          imgUrl: require('~/assets/img/room1.jpg'),
          title: 'Executive',
          quote: 'comfort, as if time is spinning too fast',
          descrription:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, laudantium a architecto eos debitis tenetur distinctio ullam repellat, exercitationem labore dolor facilis quos accusamus reiciendis expedita aliquam fugiat veniam optio?',
        },
        {
          imgUrl: require('~/assets/img/room3.jpg'),
          title: 'Standart',
          quote: 'comfort, as if time is spinning too fast',
          descrription:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, laudantium a architecto eos debitis tenetur distinctio ullam repellat, exercitationem labore dolor facilis quos accusamus reiciendis expedita aliquam fugiat veniam optio?',
        },
        {
          imgUrl: require('~/assets/img/room5.jpg'),
          title: 'Superior',
          quote: 'comfort, as if time is spinning too fast',
          descrription:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, laudantium a architecto eos debitis tenetur distinctio ullam repellat, exercitationem labore dolor facilis quos accusamus reiciendis expedita aliquam fugiat veniam optio?',
        },
        {
          imgUrl: require('~/assets/img/room6.png'),
          title: 'Deluxe',
          quote: 'comfort, as if time is spinning too fast',
          descrription:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, laudantium a architecto eos debitis tenetur distinctio ullam repellat, exercitationem labore dolor facilis quos accusamus reiciendis expedita aliquam fugiat veniam optio?',
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        breakpoints: {
          1024: {
            slidesPerView: 'auto',
          },
        },
      },
      swiperOptions2: {
        slidesPerView: 2,
        spaceBetween: 0,
        loop: true,
        breakpoints: {
          1024: {
            slidesPerView: 7,
          },
        },
      },
    }
  },

  head({ $seoMeta }) {
    return {
      title: 'Home',
      meta: $seoMeta(
        {
          title: 'Grand Hotel Picasso',
          description: 'Grand Hotel Picasso',
          url: `${process.env.APP_URL}`,
        },
        false
      ),
    }
  },
}
